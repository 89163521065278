/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1k3yqxq bg--center --full" name={"services"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: white;\"></span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--cColor1" href={"#contact"} content={""}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--50" name={"services-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"More than 5000 repairs every year"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"services-3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--3" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Tire changes"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Tire condition/wear patterns"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Tire rotation"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Tire rotation"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Oil changes"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Dent & scratch repair\n"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--60" name={"photogallery"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/124/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/124/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--30" name={"contact"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"722 Finch Street, Asbury Park, NJ 07712<br>510-851-4014<br>info@vase-stranky.com"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":""}} content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}